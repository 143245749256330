<template>
  <div></div>
</template>

<script>
export default {
  created() {
    localStorage.clear()
    this.$store.dispatch('logout', () => {
      this.$runToApp({
        appCb: () => this.$gotoWeb('/'),
        webCb: () => this.$router.replace('/'),
      }, true)
    })
  }
}
</script>
